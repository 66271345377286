import {React,useEffect,useState,useCallback,useContext} from "react";
import './cardetails.css';
import {Button,Input, Form, Radio  } from "antd";
import {useNavigate } from "react-router-dom";
import CarouselComponent from "../carousel-component/carousel-component";
import Topnavbar from "../topnavbarcomponent/topnavbar.component";
import {getData,updateData} from "../../commons/apiServices";
import Select from "../custom-elements/select";
import { InsurancesOptions } from "../../commons/selecteOptions"; 
import carDetailsModel from "../../data-model/car-details.model";
import { useDispatch,useSelector } from 'react-redux';
import ErrorHandler from "../errorhandler-component/errorhandler.component";
import { FullScreenContext } from "../../providers/fullScreenContext";
import {INITIALIZE_CARDETAILS_DATA} from "../../redux/slices/cardetailsSlice";
import { ON_CHOICE_IS_INTERESTED_IN_NEWCAR } from "../../redux/slices/userChoiceSlice";
import { closeFullScreen } from "../../utilities/utils";
function Cardetails() {

  //context 
  const fs = useContext(FullScreenContext);
  const imagesState = useSelector(state=>state.imageUpload);
  //dispatch hook to get access to store state
  const dispatch = useDispatch();

  //fill formData with new state data
  const formData = useSelector(state=>state.carDetails);
  const userState = useSelector(state=>state.userSession);
  const userChoice = useSelector(state=>state.userChoice);

  //useStates
  const navigate = useNavigate();
  const [form, setForm] = useState({insurancetype: null});
  const [isError,setErrorPage] = useState(false);
  const [imageLoading,setImage] = useState(true);
  //handlers
  const onValidate = (value, name) => {
    setError(prev => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value }
    }));
  }

  const [error, setError] = useState({
    insurancetype: {
      isReq: true,
      errorMsg: '',
      onValidateFunc: onValidate
    }
  });
  
  const validateForm = () => {
    let isInvalid = false;
    Object.keys(error).forEach((x) => {
      const errObj = error[x];
      if (errObj.errorMsg) {
        isInvalid = true;
      } else if (errObj.isReq && !form[x]) {
        isInvalid = true;
        onValidate(true, x);
      }
    });
    return !isInvalid;
  };

  //ref to FORM
  const [forms] = Form.useForm();

  //for Initial Api Data and dispatch the new data to reducer to get a new State
  useEffect(()=>{
    closeFullScreen();
    updateData('selfEvaluation/update/updateSelfEvaluationDetailsById',userState.userSession.uid,
    {
      ...imagesState.imageUpload
    }
    ).then(imageuploadRes => {
      if(imageuploadRes.status){
        getData('selfEvaluation/read/getSelfEvaluationById',userState.userSession.uid)
        .then(userInfo => {
           if(userInfo.status){
            if(userInfo.response) {
            getData('selfEvaluation/getSelfEvaluationByIdNew',userState.userSession.uid)
            .then(uploadedImages=>{
              if(uploadedImages.status){
                userInfo.response.carImages = uploadedImages.response
                  setImage(false);
                  dispatch(INITIALIZE_CARDETAILS_DATA({...new carDetailsModel.ResModel(userInfo.response)}))
               }
            })    
            }
          }
        }).catch(err=>{
          setErrorPage(true)
          formData.carDetails.loading = false;
        })
      }
    }).catch(error=>{
      setErrorPage(true);   
    });

    // if (window.screen.orientation.lock) {
    //   window.screen.orientation.lock('portrait');
    // }

    // fs();
   
    // return () => {
    //   window.screen.orientation.lock('landscape');
    //  }
    
  },[fs,dispatch,userState.userSession.uid])


  // set data to the FORM fields
  useEffect(()=> {
           forms.setFieldsValue({...formData.carDetails});
           setForm({insurancetype:formData.carDetails.insurancetype});
           dispatch(ON_CHOICE_IS_INTERESTED_IN_NEWCAR({isInterestedInNewCar:formData.carDetails.interestedinnewcar}))
          //  setNewCar(formData.carDetails.interestedinnewcar);
  },[formData,forms,dispatch])

  
  //useCallback
  const handleProvinceChange = useCallback((value, name) => {
    setForm({[name]: value});
  }, []); //re-render when form value is changed


  //handler for form submission
  const onFinish = async (values) => {

    const isValid = validateForm();

    if(!isValid){
      return
    }

    const requestObj = new carDetailsModel.ReqModel(values);

    if (requestObj) {

      updateData(
        'selfEvaluation/update/updateSelfEvaluationDetailsById',
        userState.userSession.uid,
        {
          ...requestObj,
          insurancetype:form.insurancetype
        }).then((res) => {
        if(res.status){
          getData(`selfEvaluation/getVaahanAndUpdateSelfEvaluationByEvaluationId`,userState.userSession.uid);
          navigate('/ch');
        }
      });
    }

    return
  };

  //handler for error on form submission
  const onFinishFailed = (errorInfo) => {
    return
  };

  const handleInterestedInNewCar = (e) => {
    dispatch(ON_CHOICE_IS_INTERESTED_IN_NEWCAR({isInterestedInNewCar:e.target.value}))
  }

  const validateExpectedSalePrice = (_, value) => {

    if(value === '' || value === null){
      return Promise.reject(new Error('Please only numbers'));
    }

    if (!value || /^\d+$/.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('Please only numbers'));
  };

  const validateKMS = (_, value) => {
   
    if(value === '' || value === null){
      return Promise.reject(new Error('Please only numbers'));
    }

    if (!value || /^\d+$/.test(value)) {
      // Return Promise.resolve() if validation succeeds
      return Promise.resolve();
    }

    // Return Promise.reject() if validation fails
    return Promise.reject(new Error('Please only numbers'));
  };

  const noSpecialCharRegNumber = (_, value) => {
     
    if(value === '' || value === null){
      return Promise.reject(new Error('Please only numbers'));
    }

    if (!value || /^[a-zA-Z0-9_]*$/.test(value)) {
      // Return Promise.resolve() if validation succeeds
      return Promise.resolve();
    }

    // Return Promise.reject() if validation fails
    return Promise.reject(new Error('Special characters are not allowed'));
  };


  //JSX's
  return (
    <>
    { !isError ? <div className="conditionAndHistory_main">
          <Topnavbar title="Car Details" prevpath="/fvp"/>
            <Form
                form={forms}
                name="basic"
                layout="vertical"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
            {/* <div className="caroulse-holder">
            { 
             imageLoading ? <div style={{display:"flex",justifyContent:"center",height:"150px",alignItems:"center"}}> <div className="loader"></div> </div> : 
             <CarouselComponent carImages={formData.carDetails.carImages}/>
             }
            </div> */}

            <div className="formholder-cardetails">
                <p className="question-head">Please enter your Registration Number</p>
                  <Form.Item
                        name="regnumber"
                        rules={[
                          {
                            validator:noSpecialCharRegNumber,
                            required: true,
                            message: "Please Enter Registration Number", //Marks as Required Field
                          },
                        ]}
                      >
                        <Input
                          onChange={e=>forms.setFieldsValue({'regnumber':e.target.value.toLocaleUpperCase()})}
                          placeholder="Registration Number"
                          size="small" 
                          className="input-user"
                          autoComplete="off"
                        />
                  </Form.Item>

                  <p className="question-head">How much do you expect to get for it?</p>
                  <Form.Item
                        name="expectedsaleprice"
                        rules={[
                          {
                            validator:validateExpectedSalePrice,
                            required: true,
                            message: "Please enter valid expected price", //Marks as Required Field
                          },
                        ]}
                      >
                        <Input
                          placeholder="Expected Price"
                          size="small"
                          className="input-user"
                          autoComplete="off"
                        />
                  </Form.Item>

                  <p className="question-head">How many KMS has your car done?</p>
                  <Form.Item
                        name="kms"
                        rules={[
                          {
                            validator:validateKMS,
                            required: true,
                            message: "Please enter KMS", //Marks as Required Field
                          },
                        ]}
                      > 
                        <Input
                          placeholder="KMS"
                          size="small" //Can keep small || medium || large
                          className="input-user"
                          autoComplete="off"
                        />
                  </Form.Item>

                  
                  <div className="dropdown-holder">
                  <p className="question-head">What kind of insurance is it?</p>
                  
                 <Select
                    name="insurancetype"
                    selectname="insurance"
                    options={InsurancesOptions}
                    value={form.insurancetype}
                    onChangeFunc={handleProvinceChange}
                    {...error.insurancetype}
                    />
                  
                  </div>
                  <p className="question-head">Are you the original owner?</p>
                  <Form.Item
                    name="issellerowner"
                    rules={[
                      {
                        required: true,
                        message: "Please pick an item!",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  <p className="question-head">Do you have an outstanding loan against your Car?</p>
                  <Form.Item
                    name="outstandingloan"
                    rules={[
                      {
                        required: true,
                        message: "Please pick an item!",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  
                  <p className="question-head">Has it been serviced at an Authorized Service Station?</p>
                    <Form.Item
                    name="isauthorizedservice"
                    rules={[
                      {
                        required: true,
                        message: "Please pick an item!",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                    </Form.Item>

                    <p className="question-head">Interested in New Car ?</p>
                    <Form.Item
                    name="interestedinnewcar"
                  >
                    <Radio.Group onChange={handleInterestedInNewCar} value={userChoice.userChoice.isInterestedInNewCar}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                    </Form.Item>

                    { userChoice.userChoice.isInterestedInNewCar &&
                                        <Form.Item
                                        name="makemodel"
                                      >
                                        <Input
                                          placeholder="Make/Model"
                                          size="small" 
                                          className="input-user"
                                          autoComplete="off"
                                        />
                                  </Form.Item>
                    }

            </div>

            <div className="button-holder">
              <Button
                size="large"
                htmlType="submit"
                className="button-5"
              >
              Next
              </Button>
            </div>
            
            </Form>
        </div> : <ErrorHandler/>}
    </>
  );
}

export default Cardetails;
