export const closeFullScreen = async () => {
    if (document.exitFullScreen) return document.exitFullScreen();
    else if (document.webkitExitFullscreen)
      return document.webkitExitFullscreen();
    else if (document.msExitFullscreen) return document.msExitFullscreen();
    else if (document.mozCancelFullScreen) return document.mozCancelFullScreen();
  };
  
export const goFullscreen = async (componentMounted) => {
  if (componentMounted) {

    var docElm = document.documentElement;
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    } else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen();
    } else if(docElm.webkitRequestFullscreen){
      docElm.webkitRequestFullscreen();
    }
  }
}
  