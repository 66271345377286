import React, { useState, useEffect, useContext } from "react";
import { MdDone, MdReplay, MdScreenLockRotation } from "react-icons/md";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import "./cameracomponent.css";
import {CameraContext} from "../../providers/CameraContext";
// import { FullScreenContext } from "../../providers/fullScreenContext";
import { useNavigate } from "react-router-dom";
import { closeFullScreen } from "../../utilities/utils";
const {cameraResolution} = require('../../config.json');

export const AndroidCamera = ({
  sendPost,
  setImage,
  splashImage,
  cameraMode,
  otherProps,
  height,
  nextpath
}) => {
  const [camera, setCamera] = useState(true); //Camera start and stop state
  const [show, setShow] = useState(false); // Image Preview
  const [tempimage, setTempImage] = useState(null); // Displays captured Image
  const userDevice = useContext(CameraContext); //Handles camera landscape and potrait Mode
  // const fullScreen = useContext(FullScreenContext);
  const navigate = useNavigate(); //Changes routes
  const [warnLandscape, setWarnLandscape] = useState(true);
  const [isLandscape, setIslandscape] = useState(false);


  function handleTakePhoto(dataUri) {
    setCamera(false); //Closes Camera
    setTempImage(dataUri); //Caputred Images
    setImage(dataUri); //
    setWarnLandscape(false);
    setShow(true);
  }

  function retakePhoto() {
    setCamera(true); //Enables Camera
    setShow(false); //Hides image preview or just basically clear image which was cleared by user
  }

  useEffect(() => {
  
    if (userDevice.size.x < userDevice.size.y) {
      setCamera(false);
      console.log("Potrait");
      setWarnLandscape(true);
      setIslandscape(false);
      setShow(false);
    } else {
      setCamera(true);
      console.log("Landscape");
      setWarnLandscape(false);
      setIslandscape(true);
      setShow(false);
    }
  }, [userDevice]);

  useEffect(() => {
    let componentMounted = true;
    const fullScreen = async () => {
      if (componentMounted) {
        var docElm = document.documentElement;
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen().catch((err) => console.log(err));
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen().catch((err) => console.log(err));
        }
        window.screen.orientation.lock("landscape");
      }
    };
    fullScreen();
    return () => {
      componentMounted = false;
    };
  }, []);

  return (
    <div>
      {warnLandscape
        ? warnLandscape && (
            <div>
              <MdScreenLockRotation
                size={200}
                style={{
                  color: "black",
                  margin: "auto",
                  display: "block",
                  marginTop: "20px",
                }}
              />
              <h1
                style={{
                  color: "black",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                Rotate your screen for better experience.
              </h1>
            </div>
          )
        : camera && (
            <div className="camera-landscpe">
              <Camera
                idealResolution = {cameraResolution}
                className="htmlcheck"
                onTakePhoto={(dataUri) => {
                  handleTakePhoto(dataUri);
                }}
                onCameraError={(error) => {
                  closeFullScreen();
                  navigate(nextpath);
                }}
                idealFacingMode={
                  cameraMode === "frontCamera"
                    ? FACING_MODES.USER
                    : FACING_MODES.ENVIRONMENT
                }
                isImageMirror={false}
                imageCompression={0}
                isFullscreen={true}
              />
            </div>
          )}
      {splashImage && (
        <img
          src={splashImage}
          {...otherProps}
          height={height ? height : 260}
          className={`${
            isLandscape && camera ? "camera-splash splash-show" : "splash-hide"
          }`}
          alt="sheerdrive-cam"
        />
      )}
      {show && (
        <div className="Cam-Img">
          <p className="para retake">Retake</p>
          <MdReplay
            className="send-button retry"
            size="35px"
            onClick={retakePhoto}
          />
          <img src={tempimage} alt="sheerdrive" />
          <p className="para approve">Approve</p>
          <MdDone className="send-button" size="35px" onClick={sendPost} />
        </div>
      )}
    </div>
  );
};

export default AndroidCamera;
