import React, { useEffect, useRef, useState } from 'react';
import './startevaluationpage.css';
import pinImage from "./../../assets/pin.png";
import machineImage  from "./../../assets/machine.png";
import focusImage from "./../../assets/focus.png"
import doneImage from "./../../assets/done.png"
import downloadImage from "./../../assets/download.png"
import moneyBackImage from "./../../assets/money-back-guarantee.png"
import backEngine from './../../assets/left-arrow.svg';
import CaptureImg from './../../assets/howitsworks/CaptureImg.jpg';
import Donfivemin from './../../assets/howitsworks/Donfivemin.jpg';
import DownloadReport from './../../assets/howitsworks/DownloadReport.jpg';
import GuaranteedOffer from './../../assets/howitsworks/GuaranteedOffer.jpg';
import RegNo from './../../assets/howitsworks/RegNo.jpg';
import verifymobile from './../../assets/howitsworks/verifymobile.png';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

function Regpage () {

   const [showVerifymobile, setVerifymobile] = useState({display:"block"});
   const [showCaptureImg, setCaptureImg] = useState({display:"none"});
   const [showDonfivemin, setDonfivemin] = useState({display:"none"});
   const [showDownloadReport, setDownloadReport] = useState({display:"none"});
   const [showGuaranteedOffer, setGuaranteedOffer] = useState({display:"none"});
   const [showRegNo, setRegNo] = useState({display:"none"});

   const navigate = useNavigate();
   const userState = useSelector(state=>state.userSession);
   
   console.log(userState);

return (
    <div className="howitworks_main">
  <div className="GFG">
   <div className="back-button" onClick={()=>{navigate('/eval')}}><img src={backEngine} alt=""/></div>
   <div className="howitworks-title"><h4>How it Works!</h4></div>
   <div className="cover">
      <img src={verifymobile} alt='verifymobile' style={showVerifymobile}/>
      <img src={CaptureImg} alt='CaptureImg' style={showCaptureImg}/>
      <img src={Donfivemin} alt='Donfivemin' style={showDonfivemin}/>
      <img src={DownloadReport} alt='CaptureImg' style={showDownloadReport}/>
      <img src={GuaranteedOffer} alt='CaptureImg' style={showGuaranteedOffer}/>
      <img src={RegNo} alt='CaptureImg' style={showRegNo}/>
   </div>
    <div className="RRR">
         <div style={showVerifymobile}>
            <h4>Verify Your Mobile No</h4>
            <p>We prioritize security and accuracy, so we'll need to verify your mobile number to get started</p>
         </div>
         <div style={showCaptureImg}>
            <h4>Capture Images of Your Car</h4>
            <p>Take clear pictures of your car along with any standard industry disclosures to ensure an accurate evaluation.</p>
         </div>
         <div style={showDonfivemin}>
            <h4>You'll be done in 5 mins</h4>
            <p>Our streamlined process is designed to be quick and hassle-free, taking only 5 minutes of your time.</p>
         </div>
         <div style={showDownloadReport}>
            <h4>Downloadable Report</h4>
            <p>Once you complete the evaluation, you'll receive a comprehensive report that outlines the value of your car.</p>
         </div>
         <div style={showGuaranteedOffer}>
            <h4>Guaranteed Offer in 15 mins</h4>
            <p>You won't have to wait long! In just 15 minutes, you'll receive a guaranteed offer for your car based on the evaluation results.</p>
         </div>
         <div style={showRegNo}>
            <h4>Enter Your Car Registration No</h4>
            <p>Enter your car's registration number to provide us with the necessary details for evaluation.</p>
         </div>
    </div>

  </div>
  <div className="steps">
   <p style={{marginTop:'5px'}}>Evaluate your car's worth in just a few simple steps</p>
  </div>
  <div className="container">
   
   {/* Verify your Mobile Number */}
  <div className="box" onClick={()=>{
      setCaptureImg({display:"none"})
      setVerifymobile({display:"block"});
      setDonfivemin({display:"none"});
      setDownloadReport({display:"none"});
      setGuaranteedOffer({display:"none"});
      setRegNo({display:"none"});
  }}>
     <div className = "icons">
     <img src={pinImage} alt="pin"/>
     <div>Verify your Mobile Number</div> 
     </div>   

    </div>

   {/* Enter Your Car Registration No */}

  <div className="box" onClick={()=>{
      setCaptureImg({display:"none"})
      setVerifymobile({display:"none"});
      setDonfivemin({display:"none"});
      setDownloadReport({display:"none"});
      setGuaranteedOffer({display:"none"});
      setRegNo({display:"block"});
  }}>
  <div className = "icons">
     <img src={machineImage} alt="machine"/>
     <div>Enter Your Car Registration No</div> 
     </div>  
  </div>

   {/* Capture Images of Your Car */}

  <div className="box" onClick={()=>{
      setCaptureImg({display:"block"})
      setVerifymobile({display:"none"});
      setDonfivemin({display:"none"});
      setDownloadReport({display:"none"});
      setGuaranteedOffer({display:"none"});
      setRegNo({display:"none"});
  }}>

  <div className = "icons">
     <img src={focusImage} alt="focus"/>
     <div>Capture Images of Your Car</div> 
     </div>  
  </div>

     {/* You'll be done in 5 mins */}

  <div className="box" onClick={()=>{
      setCaptureImg({display:"none"})
      setVerifymobile({display:"none"});
      setDonfivemin({display:"block"});
      setDownloadReport({display:"none"});
      setGuaranteedOffer({display:"none"});
      setRegNo({display:"none"});
  }}>
  <div className = "icons">
     <img src={doneImage} alt="done"/>
     <div>You'll be done in 5 mins</div> 
     </div>  
  </div>

     {/* Receive a Complete Downloadable Report */}

   <div className="box" onClick={()=>{
      setCaptureImg({display:"none"})
      setVerifymobile({display:"none"});
      setDonfivemin({display:"none"});
      setDownloadReport({display:"block"});
      setGuaranteedOffer({display:"none"});
      setRegNo({display:"none"});
  }}>
   <div className = "icons">
     <img src={downloadImage} alt="download"/>
     <div>Receive a Complete Downloadable Report</div> 
     </div>  
   </div>

     {/* Guaranteed Offer in 15 mins */}

  <div className="box" onClick={()=>{
      setCaptureImg({display:"none"})
      setVerifymobile({display:"none"});
      setDonfivemin({display:"none"});
      setDownloadReport({display:"none"});
      setGuaranteedOffer({display:"block"});
      setRegNo({display:"none"});
  }}>
  <div className = "icons">
     <img src={moneyBackImage} alt="money back"/>
     <div>Guaranteed Offer in 15 mins</div> 
     </div>  
  </div>
 
</div>

    <div className="button-holder">
        <button className="button-5" onClick={()=>{navigate('/fvp')}}>Start Evaluation</button>
        </div>  
    </div>
)
}


export default Regpage;