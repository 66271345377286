import { createSlice } from "@reduxjs/toolkit";

const initialState = {
      carDetails:{ 
            loading:true,
            regnumber:"",
            expectedsaleprice:"",
            kms:"",
            issellerowner:false,
            outstandingloan:false,
            isauthorizedservice:false,
            interestedinnewcar:false,
            insurancetype:"",
            makemodel:"",
            carImages:[]
      }
}


const reducers = {
    INITIALIZE_CARDETAILS_DATA(state,action){
        state.carDetails = {...state.carDetails, ...action.payload};
    }
}

const sliceOption = {
    name:'carDetails',
    initialState,
    reducers
}

const cardetailsSlice = createSlice(sliceOption);

export const {
    INITIALIZE_CARDETAILS_DATA    
} = cardetailsSlice.actions

export default cardetailsSlice.reducer;


