import carDetailsReducer from "./slices/cardetailsSlice";
// import carConditionReducer from "./slices/carconditionSlice"; // redux not implemented yet
import userSessionReducer from "./slices/userSessionSlice";
import imageUploadReducer from './slices/imageUploadSlice';
import userChoiceReducer from "./slices/userChoiceSlice";

const rootReducer = (state = {}, action) => {
    return {
        carDetails: carDetailsReducer(state.carDetails,action),
        imageUpload: imageUploadReducer(state.imageUpload, action),
        userSession: userSessionReducer(state.userSession, action),
        userChoice:userChoiceReducer(state.userChoice,action)
    };
  };


export default rootReducer;
