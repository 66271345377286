import {React,useEffect} from "react";
import './mobilenumber-page.css';
import Mobileform from "./mobile-form-component/mobile-form.component";
import carmobileviewcam from "./../../assets/mobile-car.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function MobilenumberPage() {

  const navigate = useNavigate();
  
  const initialState = useSelector(state=>state.userSession);

  useEffect(()=>{
    if(initialState.userSession.sheerAuthenticate){
      navigate("/eval")
      window.addEventListener('popstate',()=>{
        navigate("/eval")
      })
    }
  })

  return (
        <div className="mobileno_view_main">
        
        <div className="hero">
        <div className="mobileno_view_title">
            <h2>Selling Your Car?</h2>
            <p>We'll help you get the Best Price for it!!</p>
        </div>
        <div className="mobileno_view_image">
            <img src={carmobileviewcam} alt="car"/> 
        </div>
        </div>

        <div className="mobileinput_main">
          <Mobileform/>
        </div>
        </div>
  );
}

export default MobilenumberPage;
