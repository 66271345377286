import {React} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import {persistor,store } from './redux/state-store';
import { PersistGate } from 'redux-persist/integration/react';   

try{
  
  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
      //<StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <App />
        </PersistGate>
      </Provider>
     // </StrictMode>
  
  );

}catch(err){
  console.log(err);
}
