import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import "./mobile-form.css";
import { postData } from "../../../commons/apiServices";
import initializeModel from "../../../data-model/initialize.model";
import { ON_MOBILE_NUMBER_ACCEPTED } from "../../../redux/slices/userSessionSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Mobileform() {
  const userState = useSelector((state) => state.userSession);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = function (values) {
    setLoading(true);
    if (values) {
      if (!userState.userSession.urllead) {
        postData("selfLead/saveSelfEvalLeadFromApp", "", {
          leadid: userState.userSession.leadid,
          store: userState.userSession.store,
          execid:userState.userSession.execid,
          phonenumber: values.mobileNumber,
        });
      }
      postData(
        "selfEvaluation/write/saveSelfEvaluationDetails",
        "",
        new initializeModel.ReqModel(
          userState.userSession.leadid,
          values.mobileNumber,
          Date.now()
        )
      )
        .then((res) => {
          if (res.status) {
            setLoading(false);
            dispatch(
              ON_MOBILE_NUMBER_ACCEPTED({
                uid: res.response[0].id,
                sheerAuthenticate: true,
                userNumber: values.mobileNumber,
              })
            );
            navigate("/eval");
          } else {
            navigate("/error");
          }
        })
        .catch((err) => {
          navigate("/error");
        });
    }
    return null;
  };

  const onInputError = function (errorInfo) {
    return null;
  };

  return (
    <div className="form-main">
      <div className="form-title">
        <h1>Let's verify your Mobile Number</h1>
        <h1>&</h1>
        <h1>Get Started</h1>
      </div>
      <Form
        name="basic"
        layout="vertical"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        onFinishFailed={onInputError}
      >
        <Form.Item
          name="mobileNumber"
          className="signup-item"
          rules={[
            {
              required: true,
              message: (
                <span className="login-error">
                  Please enter valid 10 digit phone number
                </span>
              ),
              pattern: new RegExp(/^[0-9]+$/),
            },
            {
              pattern: /^[\d]{10,10}$/,
              message: (
                <span className="login-error">
                  Please enter valid 10 digit phone number
                </span>
              ),
            },
          ]}
        >
          <div className="input-holder">
            <Input
              size={"large"}
              className="signin"
              type="number"
              placeholder="Enter Mobile Number"
              autoComplete="off"
            />
          </div>
        </Form.Item>
        <div className="btn-holder">
          <Button
            size="large"
            htmlType="submit"
            style={{ marginTop: "8%" }}
            className="button-5"
            loading={loading}
            disabled={loading}
          >
            {!loading && <span>Proceed</span>}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default Mobileform;
