import axios from "axios";
const { localPath, prodPath, isProd } = require("../config.json");

const postData = async (endpoint, uid, body) => {
  let response = await axios.post(
    `${(isProd && prodPath) || localPath}/${endpoint}/${uid}`,
    body
  );
  if (response) {
    return response.data;
  }
};

const getData = async (endpoint, uid) => {
  let response = await axios.get(
    `${(isProd && prodPath) || localPath}/${endpoint}/${uid}`
  );
  if (response) {
    return response.data;
  }
};

const updateData = async (endpoint, uid, body) => {
  let response = await axios.patch(
    `${(isProd && prodPath) || localPath}/${endpoint}/${uid}`,
    body
  );
  if (response) {
    return response.data;
  }
};

//tp
const checkCondition = {
  checkedOne: false,
  checkedTwo: false,
};

export { getData, updateData, postData, checkCondition };
