import React,{useEffect, useState,useContext} from "react";
import {MdScreenLockRotation } from "react-icons/md";
import './interiorview.css';
import interior from "../../../assets/interior-view.svg";
import { useNavigate } from "react-router-dom";
import Cameracomponent from "../../camera-component/camera-component";
import backEngine from '../../../assets/left-arrow.svg';
import uploadImage from "../../../commons/firebaseImageUpload";
import { CameraContext } from "../../../providers/CameraContext";
import ErrorHandler from "../../errorhandler-component/errorhandler.component";
import { uid } from "uid";
import { updateData } from "../../../commons/apiServices";
import Loader from "../../loader-component/loader-component";
import { useDispatch, useSelector } from "react-redux";
import { ON_IMAGE_CAPTURED } from "../../../redux/slices/imageUploadSlice";
const {cameraFace} = require('../../../config.json');


function InteriorViewPage() {
  const navigate = useNavigate();
  const [isReady,setReady] = useState(false);
  const [image, setImage] = useState(null);
  const [warnLandscape, setWarnLandscape] = useState(false);
  const userDevice = useContext(CameraContext);
  const [isLoading,setLoading] = useState(false);
  const cameraMode = cameraFace;
  var temp = uid(16);
  
  const userState = useSelector(state=>state.userSession);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userDevice.size.x < userDevice.size.y) {
      setWarnLandscape(prevstate=> prevstate = true);
    } else {
      setWarnLandscape(prevstate=> prevstate = false);
    }
  }, [userDevice]);

  const sendPost = async () => {
    setLoading(true);
    uploadImage(image,temp,userState.userSession.uid).then(url=>{
      dispatch(ON_IMAGE_CAPTURED({interiorview:url}));   
      setLoading(false); 
      navigate("/cd");
    }).catch(error=>{
      console.log("firebase",error);      
    }); 
  };

  return (
    <>
    {
       warnLandscape ? <div 
       style={{
       display:"flex",
       height:"100%",
       flexDirection: "column",
       justifyContent: "center"
       }}> 
         <div>
         <MdScreenLockRotation
                 size={200}
                 style={{
                   color: "black",
                   margin: "auto",
                   display: "block",
                   marginTop: "20px",
                 }}
               />
               <h1
                 style={{
                   color: "black",
                   textAlign: "center",
                   paddingTop: "20px",
                 }}
               >
                 Rotate your screen for better experience.
               </h1>
         </div>
         </div> 
     : isLoading ? <Loader/> 
     : isReady ? <Cameracomponent 
                  sendPost={sendPost} 
                  nextpath="/cd" 
                  setImage={setImage} 
                  cameraMode={cameraMode} 
                  /> : <div className="interior_main">
         <div className="back-button" onClick={()=>{navigate('/bvp')}}><img src={backEngine} alt="back"/></div>
        <div className="interior_image">
            <img src={interior} alt="interior"/>
        </div>
         
         <div className="interior">
                <div className="interior_text">
                  <p>Next you will Open driver's door, and take a photo of the interior as illustrated by these lines.</p> 
                </div>
         </div>

         <div className="button-holder">
            <button className="button-5" onClick={()=>{setReady(true)}}>Next</button>
            </div>

        </div>}
    </>
  );
}

export default InteriorViewPage;
