import React,{useEffect, useState,useContext} from "react";
import {MdScreenLockRotation } from "react-icons/md";
import './rightviewcampage.css';
import carrightviewcam from "../../../assets/right-view.svg";
import { useNavigate } from "react-router-dom";
import Cameracomponent from "../../camera-component/camera-component";
import backEngine from '../../../assets/left-arrow.svg';
import { uid } from "uid";
import uploadImage from "../../../commons/firebaseImageUpload";
import { CameraContext } from "../../../providers/CameraContext";
import ErrorHandler from "../../errorhandler-component/errorhandler.component";
import Loader from "../../loader-component/loader-component";
import { useDispatch, useSelector } from "react-redux";
import { ON_IMAGE_CAPTURED } from "../../../redux/slices/imageUploadSlice";
const {cameraFace}= require( '../../../config.json');

function Rightviewcampage() {

  const navigate = useNavigate();
  const [isReady,setReady] = useState(false);
  const [image, setImage] = useState(null);
  const [warnLandscape, setWarnLandscape] = useState(false);
  const cameraMode = cameraFace;
  const userDevice = useContext(CameraContext);
  const [isLoading,setLoading] = useState(false);
  const dispatch = useDispatch();
  const userState = useSelector(state=>state.userSession);
  const imagestates = useSelector(state=>state.imageUpload);
  var temp = uid(16);
  
  useEffect(() => {
    console.log(imagestates);
    if (userDevice.size.x < userDevice.size.y) {
      setWarnLandscape(prevstate=> prevstate = true);
    } else {
      setWarnLandscape(prevstate=> prevstate = false);
    }
  }, [userDevice]);

  const sendPost = async () => {
    setLoading(true);
    uploadImage(image,temp,userState.userSession.uid).then(url=>{
      dispatch(ON_IMAGE_CAPTURED({rightview:url}));
      setLoading(false);
      navigate("/lvp");      
    }).catch(error=>{
      console.log("firebase",error);
      <ErrorHandler errorCode={error}/>          
    }); 

  };
  return (
    <>
      {
            warnLandscape ? <div 
            style={{
            display:"flex",
            height:"100%",
            flexDirection: "column",
            justifyContent: "center"
            }}> 
              <div>
              <MdScreenLockRotation
                      size={200}
                      style={{
                        color: "black",
                        margin: "auto",
                        display: "block",
                        marginTop: "20px",
                      }}
                    />
                    <h1
                      style={{
                        color: "black",
                        textAlign: "center",
                        paddingTop: "20px",
                      }}
                    >
                      Rotate your screen for better experience.
                    </h1>
              </div>
              </div>  : isLoading ? <Loader/> : isReady 
      ? <Cameracomponent 
      sendPost={sendPost} 
      nextpath="/lvp" 
      setImage={setImage} 
      cameraMode={cameraMode} 
      /> 
      :         
      <div className="car_rightview_main"> 
      <div className="back-button" onClick={()=>{navigate('/fvp')}}><img src={backEngine} alt="back"/></div>
      <div className="car_rightview_image">
          <img src={carrightviewcam} alt=""/>
      </div>
       
       <div className="car_rightview">
              <div className="car_rightview_text">
              <p>Next you will move to the vehicle's right side and align yourself according</p> 
              <p>to this illustration. Please fit the vehicle within these lines.</p>
              </div>
       </div>

       <div className="button-holder">
          <button className="button-5" onClick={()=>{setReady(true)}}>Next</button>
          </div>

      </div>}
    </>
  );
}

export default Rightviewcampage;
