import React,{useEffect, useState,useContext} from "react";
import {MdScreenLockRotation } from "react-icons/md";
import './backviewcampage.css';
import carbackviewcam from "../../../assets/back-view.svg";
import { useNavigate } from "react-router-dom";
import Cameracomponent from "../../camera-component/camera-component";
import backEngine from '../../../assets/left-arrow.svg';
import uploadImage from "../../../commons/firebaseImageUpload";
import { CameraContext } from "../../../providers/CameraContext";
import { uid } from "uid";
import Loader from "../../loader-component/loader-component";
import { useDispatch, useSelector } from "react-redux";
import { ON_IMAGE_CAPTURED } from "../../../redux/slices/imageUploadSlice";
const {cameraFace} = require('../../../config.json');


function Backviewcampage() {
  const navigate = useNavigate();
  const [isReady,setReady] = useState(false);
  const [image, setImage] = useState(null);
  const cameraMode = cameraFace;
  const userDevice = useContext(CameraContext);
  const [warnLandscape, setWarnLandscape] = useState(false);
  const [isLoading,setLoading] = useState(false);
  var temp = uid(16);
  const dispatch = useDispatch();
  const userState = useSelector(state=>state.userSession);

  const sendPost = async () => {
    setLoading(true);
    uploadImage(image,temp,userState.userSession.uid).then((url) => {
      dispatch(ON_IMAGE_CAPTURED({rearview:url}));
      setLoading(false);
      navigate("/icp");      
    }).catch(error=>{
      console.log(error);
      });
  };

  useEffect(() => {
    if (userDevice.size.x < userDevice.size.y) {
      setWarnLandscape(prevstate=> prevstate = true);
    } else {
      setWarnLandscape(prevstate=> prevstate = false);
    }
  }, [userDevice]);
  
  return (
       <>
       {
        warnLandscape ? <div 
        style={{
        display:"flex",
        height:"100%",
        flexDirection: "column",
        justifyContent: "center"
        }}> 
          <div>
          <MdScreenLockRotation
                  size={200}
                  style={{
                    color: "black",
                    margin: "auto",
                    display: "block",
                    marginTop: "20px",
                  }}
                />
                <h1
                  style={{
                    color: "black",
                    textAlign: "center",
                    paddingTop: "20px",
                  }}
                >
                  Rotate your screen for better experience.
                </h1>
          </div>
          </div>          
          : isLoading ? <Loader/> 
          : isReady ?  <Cameracomponent 
                        sendPost={sendPost} 
                        nextpath="/bvp" 
                        setImage={setImage} 
                        cameraMode={cameraMode} 
                        /> 
          :
       <div className="car_backview_main">
       <div className="back-button" onClick={()=>{navigate('/lvp')}}> 
        <img src={backEngine} alt="back"/>
       </div>
       <div className="car_backview_image">
           <img src={carbackviewcam} alt=""/>
       </div>
        
        <div className="car_backview">
               <div className="car_backview_text">
               <p>Next you will move to the vehicle's rear and align yourself according to</p> 
               <p>this illustration. Please fit the vehicle within these lines.</p>
               </div>
        </div>

        <div className="button-holder">
           <button className="button-5" onClick={()=>{setReady(true)}}>Next</button>
           </div>
       </div>
       }
       </>
  );
}

export default Backviewcampage;
