const carDetailsModel = {
    ReqModel: class {
            constructor(
                {regnumber,
                expectedsaleprice,
                kms,
                issellerowner,
                outstandingloan,
                isauthorizedservice,
                interestedinnewcar,
                makemodel,
                }){
                    this.regnumber = regnumber;
                    this.expectedsaleprice = parseInt(expectedsaleprice);
                    this.kms = kms;
                    this.issellerowner = issellerowner;
                    this.outstandingloan = outstandingloan;
                    this.isauthorizedservice = isauthorizedservice;
                    this.interestedinnewcar = interestedinnewcar
                    this.newcardetail = interestedinnewcar ? makemodel : "";
            }

            getReqObject() {
              return this;
            }
    },
    ResModel:class {
            constructor(
            {regnumber,
            expectedsaleprice,
            kms,
            issellerowner,
            outstandingloan,
            isauthorizedservice,
            interestedinnewcar,
            insurancetype,
            newcardetail,
            carImages}){
                this.regnumber = regnumber;
                this.expectedsaleprice = expectedsaleprice;
                this.kms = kms;
                this.issellerowner = issellerowner;
                this.outstandingloan = outstandingloan;
                this.isauthorizedservice = isauthorizedservice;
                this.interestedinnewcar = interestedinnewcar;
                this.insurancetype = insurancetype;
                this.makemodel = newcardetail;
                this.carImages = carImages ? carImages : [];
        }

        getReqObject() {
            return this;
        }
    }
}

export default carDetailsModel;