const initializeModel = {
    ReqModel: class {
        
            constructor(
                leadid,
                ownernumber,
                createdat)
                {
                    this.leadid = leadid;
                    this.ownernumber = ownernumber;
                    this.createdat = createdat;
                }

            getReqObject() {
              return this;
            }
    }
}

export default initializeModel;


