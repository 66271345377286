import React,{useEffect, useState,useContext} from "react";
import {MdScreenLockRotation } from "react-icons/md";
import './instrumentclusterviewcam.css';
import instrumentcluster from "../../../assets/instrument-cluster.svg";
import { useNavigate } from "react-router-dom";
import Cameracomponent from "../../camera-component/camera-component";
import backEngine from '../../../assets/left-arrow.svg';
import uploadImage from "../../../commons/firebaseImageUpload";
import { CameraContext } from "../../../providers/CameraContext";
import { uid } from "uid";
import Loader from "../../loader-component/loader-component";
import { useDispatch, useSelector } from "react-redux";
import { ON_IMAGE_CAPTURED } from "../../../redux/slices/imageUploadSlice";
const {cameraFace} = require('../../../config.json');


function Instrumentclusterviewcampage() {
  const navigate = useNavigate();
  const [isReady,setReady] = useState(false);
  const [image, setImage] = useState(null);
  const [warnLandscape, setWarnLandscape] = useState(false);
  const userDevice = useContext(CameraContext);
  const [isLoading,setLoading] = useState(false);
  const cameraMode = cameraFace;
  var temp = uid(16);
  const dispatch = useDispatch();
  const userState = useSelector(state=>state.userSession);
  
  useEffect(() => {
    if (userDevice.size.x < userDevice.size.y) {
      setWarnLandscape(prevstate=> prevstate = true);
    } else {
      setWarnLandscape(prevstate=> prevstate = false);
    }
  }, [userDevice]);

  const sendPost = async () => {
    setLoading(true);
    uploadImage(image,temp,userState.userSession.uid).then(url=>{
      dispatch(ON_IMAGE_CAPTURED({instrumentcluster:url}))
      setLoading(false);
      navigate("/int");
    }).catch(error=>{
        console.log("firebase",error);   
      }); 
  };

  return (
    <>
    {
       warnLandscape ? <div 
       style={{
       display:"flex",
       height:"100%",
       flexDirection: "column",
       justifyContent: "center"
       }}> 
         <div>
         <MdScreenLockRotation
                 size={200}
                 style={{
                   color: "black",
                   margin: "auto",
                   display: "block",
                   marginTop: "20px",
                 }}
               />
               <h1
                 style={{
                   color: "black",
                   textAlign: "center",
                   paddingTop: "20px",
                 }}
               >
                 Rotate your screen for better experience.
               </h1>
         </div>
         </div> 
     : isLoading ? <Loader/> 
     : isReady ? <Cameracomponent 
                  sendPost={sendPost} 
                  nextpath="/lvp" 
                  setImage={setImage} 
                  cameraMode={cameraMode} 
                  /> 
      : <div className="instrumentclusterview_main">
         <div className="back-button" onClick={()=>{navigate('/bvp')}}><img src={backEngine} alt="back"/></div>
        <div className="instrumentclusterview_image">
            <img src={instrumentcluster} alt=""/>
        </div>
         
         <div className="instrumentclusterview">
                <div className="instrumentclusterview_text">
                <p>Next you will start the vehicle's engine and capture the instrument cluster according to this illustration.</p> 
                <p>Before exiting the vehicle please turn the steering wheel as far left as it will go.</p>
                </div>
         </div>

         <div className="button-holder">
            <button className="button-5" onClick={()=>{setReady(true)}}>Next</button>
            </div>

        </div>}
    </>
  );
}

export default Instrumentclusterviewcampage;
