import React, { useState } from "react";
import { SafariCam } from "./SafarICamera.js";
import { AndroidCamera } from "./androidCamera.js";
import './cameracomponent.css';
import "react-html5-camera-photo/build/css/index.css";

export const Cameracomponent = ({
  sendPost,
  setImage,
  splashImage,
  cameraMode,
  height,
  nextpath,
  ...otherProps
}) => {

  const [safari, setSafari] = useState(
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  );
    
  return (
    <div className="imgtemp">
      {
      safari ? (
        <SafariCam
          ios={safari}
          sendPost={sendPost}
          setImage={setImage}
          splashImage={splashImage}
          cameraMode={cameraMode}
          otherProps={otherProps}
          height={height}
          nextpath={nextpath}
        />
      ) : (
        <AndroidCamera
          sendPost={sendPost}
          setImage={setImage}
          splashImage={splashImage}
          cameraMode={cameraMode}
          otherProps={otherProps}
          height={height}
          nextpath={nextpath}
        />
      )}
    </div>
  );
};

export default Cameracomponent;
