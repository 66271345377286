import React, { useEffect, useState } from "react";
import "./landingpage.css";
import { uid } from "uid";
import carLandingPage from "./../../assets/landing-car.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { FullScreenContext } from "../../providers/fullScreenContext";
import { useContext } from "react";
import { ON_APP_INITIALIZE } from "../../redux/slices/userSessionSlice";
function Landingpage() {
  const [isComponentMounted, setMounted] = useState(true);
  const queryParams = new URLSearchParams(useLocation().search);
  const leadid = queryParams.get("id");
  const storeid = queryParams.get("store");
  const exec = queryParams.get('exe');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const fs = useContext(FullScreenContext);

  useEffect(() => {
    setMounted(true);
    dispatch(
      ON_APP_INITIALIZE({
        leadid: leadid ? leadid : uid(32),
        urllead: leadid ? true : false,
        execid: exec ? exec : null,
        store: storeid,
      })
    );
  }, [dispatch, leadid, storeid, exec]);

  return (
    <div className="landingpage-main">
      <div className="spacer"></div>

      <div className="section_one">
        <div className="title-box">
          <div className="title-sub">
            <span>
              <p>
                <b>Welcome to the</b>
              </p>
              <p className="title">
                <b>Self Assist Evaluation Experience</b>
              </p>
            </span>
          </div>
        </div>

        <div className="image-holder">
          <img src={carLandingPage} alt="car" />
        </div>

        <div className="button-holder">
          <button
            className="button-5"
            onClick={() => {
              navigate("login");  
              // fs(isComponentMounted);
            }}
          >
            Let's Get Started
          </button>
        </div>
      </div>

      <div className="howitworks">
        {/* <p
          onClick={() => {
            navigate("/how");
          }}
        >
          See How it Works!
        </p> */}
      </div>
    </div>
  );
}

export default Landingpage;
