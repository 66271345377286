import React, { useState, useEffect, createContext, useCallback } from "react";

export const CameraContext = createContext({});

const CameraProvider = ({ children }) => {

  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });

  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight,
    });

  const fetchSize = useCallback(()=>{
    window.onresize = updateSize;
  },[])

  useEffect(() => {
    fetchSize();
  }, [size,fetchSize]);

  return (
       <CameraContext.Provider value={{ size }}>{children}</CameraContext.Provider>
  );
};

export default CameraProvider;
