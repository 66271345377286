import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const Protectedroute = ({ children }) => {
      
        const state = useSelector(state=>state.userSession);

        if (!state.userSession.sheerAuthenticate) {
          return <Navigate to="/" replace />
        }

    return children
}

export default Protectedroute