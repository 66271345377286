import Landingpage from "./components/landingpage/landingpage.component";
import Frontviewcampage from "./components/vehicleCameraComponents/front-view-cam/frontviewcampage.component";
import Rightviewcampage from "./components/vehicleCameraComponents/right-view-cam/rightviewcampage.component";
import Backviewcampage from "./components/vehicleCameraComponents/back-view-cam/backviewcampage.component";
import Leftviewcampage from "./components/vehicleCameraComponents/left-view-cam/leftviewcampage.component";
import Instrumentclusterviewcampage from "./components/vehicleCameraComponents/instrumentcluster-view-cam/instrumentclusterviewcam.component";
import Thankyoupage from "./components/thankyoupage/thankyoupage.component";
import StartEvaluationPage from "./components/start-evaluation-screen/start-evaluation.component";
import Declarationpage from "./components/declarationpage/declaration";
import MobilenumberPage from "./components/mobilenumberpage/mobilenumber-page.component";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Protectedroute from "./components/protectedRoute/protectedroute";
import {React} from "react";
import Conditionandhistory from "./components/condition-and-history-page/condition-and-history-form.component";
import Cardetails from "./components/car-details-component/cardetails.component";
import CameraProvider from "./providers/CameraContext";
import FullScreenProvider from "./providers/fullScreenContext";
import InteriorViewPage from "./components/vehicleCameraComponents/interior-view-cam/interiorview.component";
import HowItsWorks from "./components/howitwork-component/howitworks-stateless-component";
import ErrorHandler from "./components/errorhandler-component/errorhandler.component";

function App() {
    return (
      <CameraProvider>
        {/* <FullScreenProvider> */}
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<Landingpage/>}/>
            <Route exact path="/login" element={ <MobilenumberPage/> }/>
            <Route exact path="/ch" element={ <Protectedroute ><Conditionandhistory/></Protectedroute>}/>
            <Route exact path="/cd" element={ <Protectedroute ><Cardetails/></Protectedroute>}/>
            <Route exact path="/dc" element={ <Protectedroute ><Declarationpage/></Protectedroute>}/>
            <Route exact path="/fvp" element={ <Protectedroute ><Frontviewcampage/></Protectedroute>}/>
            <Route exact path="/lvp" element={ <Protectedroute ><Leftviewcampage/></Protectedroute>}/>
            <Route exact path="/rvp" element={ <Protectedroute ><Rightviewcampage/></Protectedroute>}/>
            <Route exact path="/bvp" element={ <Protectedroute ><Backviewcampage/></Protectedroute>}/>
            <Route exact path="/icp" element={ <Protectedroute ><Instrumentclusterviewcampage/></Protectedroute>}/>
            <Route exact path="/int" element={ <Protectedroute ><InteriorViewPage/></Protectedroute>}/>
            <Route exact path="/eval" element={ <Protectedroute ><StartEvaluationPage/></Protectedroute>}/>
            <Route exact path="/ty" element={ <Protectedroute ><Thankyoupage/></Protectedroute>} />
            
            <Route exact path="/how" element={ <HowItsWorks/>}/>
            <Route exact path="/error" element={<ErrorHandler/>}/>
            <Route exact path="*" element={ <><h1 style={{textAlign:"center",marginTop:"20px"}}>404 Not Found</h1></>} />
        </Routes>
      </BrowserRouter>
        {/* </FullScreenProvider> */}
      </CameraProvider>
    );
}

export default App;
