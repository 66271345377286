import React, { useEffect, useState, useContext } from "react";
import "./thankyoupage.css";
import downloadreport from "./../../assets/download-report.gif";
import Topnavbar from "../topnavbarcomponent/topnavbar.component";
import downloadBtn from "./../../assets/download.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FullScreenContext } from "../../providers/fullScreenContext";
import { USER_SESSION_CLEANUP } from "../../redux/slices/userSessionSlice";

function Thankyoupage() {
  //context
  const fs = useContext(FullScreenContext);

  const userState = useSelector((state) => state.userSession);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  const [downloding, setDowloading] = useState(true);

  function downloadReport() {
    setDowloading(true);
    axios
      .post(
        "https://asia-south1-bikgai-server.cloudfunctions.net/report/selfEvalReportById?iswebapp=true",
        { id: userState.userSession.uid }
      )
      .then((res) => {
        if (res.data.status) {
          setUrl(res.data.response.link);
          setDowloading(false);
        }
      })
      .catch((err) => {
        navigate("/");
      });
  }

  function removeAuthentication() {
    dispatch(
      USER_SESSION_CLEANUP({
        isOtpReceived: false,
        sheerAuthenticate: false,
        userNumber: "",
        leadid: "0",
        token: "",
        uid: "",
      })
    );
  }

  useEffect(() => {
    // if (window.screen.orientation.lock) {
    //   window.screen.orientation.lock("portrait");
    // }
    // fs();
    downloadReport();
  }, []);

  return (
    <div className="thank_you_main">
      <Topnavbar title="Thank You" prevpath="/dc" type="thankyou" />
      <div className="thank_you_image_holder">
        <img src={downloadreport} alt="thank you" />
      </div>
      <div className="thank_you_text">
        <p>Thank you</p>
        <p>for your Submission</p>
      </div>
      <div className="thank_you_smallcontent">
        <div>
          <p>We will Get Back</p>
          <p>to you Shortly</p>
        </div>
      </div>
      <div className="footer">
        <div className="button-holder">
          {downloding ? (
            <div className="load-holder">
              <div className="loader"></div>
              <p>Loading Report...</p>
            </div>
          ) : (
            <a
              className="button-5"
              href={url}
              target="_blank"
              aria-disabled={downloding}
              rel="noreferrer"
              onClick={removeAuthentication}
            >
              <span>
                <span className="downl-btn">
                  <img
                    className="download-img"
                    src={downloadBtn}
                    alt="download Report"
                  />
                </span>
                Download Report
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default Thankyoupage;
