import storage from "./firebase";

const uploadImage = async (imageFile,tempid,userId) => {
    return new Promise((resolve,reject)=>{
        
        const fileName = `${tempid}_${userId}_${Date.now()}`;
        const storageRef = storage.ref();
        const fileRef = storageRef.child('selfeval-images/'+ fileName);

        const blob = dataURItoBlob(imageFile);

        fileRef.put(blob).then(() => {
            fileRef.getDownloadURL().then((url)=>{
                resolve(url);
            })
        }).catch(error=>{
          reject(error);
        })
    })

}

const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};

export default uploadImage