import React, { useState, useEffect, useContext } from "react";
import { MdDone, MdReplay, MdScreenLockRotation } from "react-icons/md";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import {CameraContext} from "../../providers/CameraContext";
import "./cameracomponent.css";
const {cameraResolution} = require('../../config.json');

export const SafariCam = ({
  ios,
  sendPost,
  setImage,
  splashImage,
  otherProps,
  height,
  cameraMode,
}) => {
  const [tempimage, setTempImage] = useState(null);
  const [show, setShow] = useState(false);
  const [cam, showCam] = useState(true);
  const userDevice = useContext(CameraContext);
  const [warnLandscape, setWarnLandscape] = useState(true);
  const [tempstate, setTempState] = useState(true);
  const [iosCamera, setIosCamera] = useState(false);

  function retakePhoto() {
    setIosCamera(true);
    setShow(false);
  }

  function handleTakePhoto(dataUri) {
    setTempImage(dataUri);
    setImage(dataUri);
    setIosCamera(false);
    setWarnLandscape(false);
  }

  useEffect(()=>{
    let componentMounted = true;
    const fullScreen = async () => {
      if (componentMounted) {
        var docElm = document.documentElement;
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen().catch((err) => console.log(err));
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen().catch((err) => console.log(err));
        }else{
          docElm.webkitRequestFullScreen().catch((err) => console.log(err));
        }
      }
    };
    fullScreen();
  })
  useEffect(() => {
    if (userDevice.size.x < userDevice.size.y) {
      setWarnLandscape(true);
      setTempState(false);
      setIosCamera(false);
      setShow(false);
    } else {
      setTempState(true);
      setWarnLandscape(false);
      tempimage ? setIosCamera(false) : setIosCamera(true);
      tempimage ? setShow(true) : setShow(false);
    }
  }, [userDevice]); //Takes Effect when the user tries to rotate or he has enabled auto-roataion

  return (
    <div className="ios">
      {warnLandscape
        ? warnLandscape && (
            <div>
              <MdScreenLockRotation
                size={200}
                style={{
                  color: "white",
                  margin: "auto",
                  display: "block",
                  marginTop: "20px",
                }}
              />
              <h1
                style={{
                  color: "white",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                Rotate your screen for better experience.
              </h1>
            </div>
          )
        : iosCamera && (
            <Camera
              className="htmlcheck"
              onTakePhoto={(dataUri) => {
                handleTakePhoto(dataUri);
                showCam(false); //close Camera
                setShow(true); //Show icons
              }}
              onCameraError={(error) => {
                console.log(error);
                // closeFullScreen();
                // navigate.("/cd");
              }}
              idealFacingMode={
                cameraMode === "frontCamera"
                  ? FACING_MODES.USER
                  : FACING_MODES.ENVIRONMENT
              }
              idealResolution = {cameraResolution}
              isImageMirror={false}
              imageCompression={0}
              isFullscreen={true}
            />
          )}
      {splashImage && (
        <img
          src={splashImage}
          style={{ width: "50%" }}
          {...otherProps}
          height={height ? height : 260}
          className={`${
            iosCamera ? "camera-splash splash-show" : "splash-hide"
          }`}
          alt="sheerdrive-cam"
        />
      )}
      {show && (
        <div className="ios">
          <p className="para retake">Retake</p>
          <MdReplay
            className="send-button retry"
            size="35px"
            onClick={retakePhoto}
          />
          <img
            src={tempimage}
            alt="sheerdrive"
            style={{ width: "100vw", height: "100vh" }}
          />
          <p className="para approve">Approve</p>
          <MdDone className="send-button" size="35px" onClick={sendPost} />
        </div>
      )}
    </div>
  );
};

export default SafariCam;
