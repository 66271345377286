    const InsurancesOptions =
        [
            { value: 'Zero Dept', label: 'Zero Dept' },
            { value: 'Comprehensive', label: 'Comprehensive' },
            { value: 'Third Party', label: 'Third Party' },
            { value: 'Expired', label: 'Expired' }
        ]

   const MechanicalIssueOptions =
        [
             { value: 'AC', label: 'AC' },
             { value: 'Brakes', label: 'Brakes' },
             { value: 'Electrical', label: 'Electrical' },
             { value: 'Engine', label: 'Engine' },
             { value: 'Sunroof Moonroof', label: 'Sunroof Moonroof' },
             { value: 'Suspension', label: 'Suspension' },
             { value: 'Transmission', label: 'Transmission' }
        ]

  const  warningLightOptions = 
        [

             { value: 'ABS Warning Light', label: 'ABS Warning Light' },
             { value: 'Battery', label: 'Battery' },
             { value: 'Brakes', label: 'Brakes' },            
             { value:'TCS', label:'TCS'},
             { value:'ABS',label:'ABS'},
             { value:'Engine Check Light',label:'Engine Check Light'},
             { value:"Air Bags",label:'Air Bags'},
             { value:'Brake System Alert',label:'Brake System Alert'},
             { value:'Low Oil Warning',label:"Low Oil Warning"}

        ]

  const carConditionOptions = [
            { value: 'Pretty Great', label: 'Pretty Great' },
            { value: 'Just Okay', label: 'Just Okay' },
            { value: 'Kind Of Rough', label: 'Kind Of Rough' },
          ]

export {
    InsurancesOptions,
    MechanicalIssueOptions,
    warningLightOptions,
    carConditionOptions
};