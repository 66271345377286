import React, {createContext} from "react";
import { goFullscreen } from "../utilities/utils";

export const FullScreenContext = createContext({});

const FullScreenProvider = ({ children }) => {

  return (
       <FullScreenContext.Provider value={goFullscreen} >{children}</FullScreenContext.Provider>
  );
};

export default FullScreenProvider;
