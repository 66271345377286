import { useNavigate } from "react-router-dom"
import somethingWentWrongImage from '../../assets/smww.png';

function ErrorHandler(){
    const navigate = useNavigate();

    return (
        <div style={{backgroundColor:"#f4f4f4",justifyContent: "center", textAlign:"center", width:"100%", height:"100%" , display:"flex",flexDirection: "column",padding:"0px 20px"}}>
            {/* <h3 style={{color:"red",marginBottom:"15px"}}>Something went Wrong!!!!!!</h3> */}
            <img style={{width:"100%"}}src={somethingWentWrongImage} alt=""></img>
            <button className="button-5" onClick={()=>{navigate('/')}}> Back to Homepage </button>
        </div>
    )
}

export default ErrorHandler