import React,{useEffect, useState,useContext ,useRef} from "react";
import {MdScreenLockRotation } from "react-icons/md";
import './frontviewcampage.css';
import Cameracomponent from "../../camera-component/camera-component";
import carfrontviewcam from "../../../assets/front-view.svg";
import { useNavigate } from "react-router-dom";
import backEngine from '../../../assets/left-arrow.svg';
import { uid } from "uid";
import uploadImage from "../../../commons/firebaseImageUpload";
import { CameraContext } from "../../../providers/CameraContext";
import Loader from "../../loader-component/loader-component";
import { useDispatch, useSelector } from "react-redux";
import { ON_IMAGE_CAPTURED } from "../../../redux/slices/imageUploadSlice";
const {cameraFace} = require('../../../config.json');

function Frontviewcampage() {
  var temp = uid(16);
  const navigate = useNavigate();
  const userDevice = useContext(CameraContext);
  const [image, setImage] = useState(null);
  const [isReady,setReady] = useState(false);
  const [warnLandscape, setWarnLandscape] = useState(false);
  const [isLoading,setLoading] = useState(false);
  const cameraMode = cameraFace;
  const dispatch = useDispatch();
  const userState = useSelector(state=>state.userSession);

  useEffect(() => {
    
    if (userDevice.size.x < userDevice.size.y) {
      setWarnLandscape(prevstate => prevstate = true);
    } else {
      setWarnLandscape(prevstate => prevstate = false);
    }
  }, [userDevice]);

  // useEffect(()=>{
  //   let componentMounted = true;
  //   const fullScreen = async () => {
  //     if (componentMounted) {
  //       var docElm = document.documentElement;
  //       if (docElm.requestFullscreen) {
  //         docElm.requestFullscreen().catch((err) => console.log(err));
  //       } else if (docElm.mozRequestFullScreen) {
  //         docElm.mozRequestFullScreen().catch((err) => console.log(err));
  //       } else if(docElm.webkitRequestFullScreen){
  //         docElm.webkitRequestFullScreen().catch((err) => console.log(err));
  //       }
  //     }
  //   };
  //   fullScreen();
  //   return () => {
  //     componentMounted = false;
  //   };
  // },[])
  
  const sendPost = async () => {
    setLoading(true);
    uploadImage(image,temp,userState.userSession.uid).then(url => {

      dispatch(ON_IMAGE_CAPTURED({frontview:url}));
      setLoading(false);
      navigate("/rvp");
          
    }).catch(error=>{
      console.log(error);
    });
  };

  return (
    <>
    {
        warnLandscape ? <div 
        style={{
        display:"flex",
        height:"100%",
        flexDirection: "column",
        justifyContent: "center"
        }}> 
          <div>
          <MdScreenLockRotation
                  size={200}
                  style={{
                    color: "black",
                    margin: "auto",
                    display: "block",
                    marginTop: "20px",
                  }}
                />
                <h1
                  style={{
                    color: "black",
                    textAlign: "center",
                    paddingTop: "20px",
                  }}
                >
                  Rotate your screen for better experience.
                </h1>
          </div>
          </div> 
        : isLoading 
        ? <Loader/> 
        : isReady
        ? <Cameracomponent sendPost={sendPost} nextpath="/rvp" setImage={setImage} cameraMode={cameraMode}/> 
        : <div className="car_frontview_main">
          {/* <div className="back-button" onClick={()=>{navigate('/eval')}}>
            <img src={backEngine} alt="back"/>
          </div> */}
          <div className="car_frontview_image">
              <img src={carfrontviewcam} alt="front car"/>
          </div>
           
           <div className="car_frontview">
                  <div className="car_frontview_text">
                  <p>Next you will move to the vehicle's front and align yourself according</p> 
                  <p>to this illustration.Please fit the vehicle within these lines.</p>
                  </div>
           </div>
      
           <div className="button-holder">
              <button className="button-5" onClick={()=>{setReady(true)}}>Next</button>
              </div>
          </div>
     }
    </>
  );
}

export default Frontviewcampage;
