import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    imageUpload:{
        frontview:"",
        leftview:"",
        rightview:"",
        rearview:"",
        instrumentcluster:"",
        interiorview:""
    }
}

const reducers = {
    ON_IMAGE_CAPTURED(state,action){
        state.imageUpload = {...state.imageUpload, ...action.payload};
    }
}

const sliceOption = {
    name:'imageUpload',
    initialState,
    reducers
}

const imageUploadSlice = createSlice(sliceOption);

//export all the actions from the slice
export const { 
    ON_IMAGE_CAPTURED
} = imageUploadSlice.actions;

export default imageUploadSlice.reducer;