const carConditionModel = {
    ReqModel: class {
            constructor(
                { 
                    isinteriordamaged,
                    warninglightsglowing,
                    interiordamages,
                    interiordamagecomment,
                    isaccidental,
                    damagebyfireflood,
                    mechanicalissueexists,
                    isdrivable
                }){

                    
                    this.warninglightsglowing = warninglightsglowing;
                    this.isinteriordamaged = isinteriordamaged;
                    this.interiordamages = (isinteriordamaged && interiordamages) || [];
                    this.interiordamagecomment = (isinteriordamaged && interiordamagecomment) || "";
                    this.isaccidental = isaccidental;
                    this.damagebyfireflood = damagebyfireflood;
                    this.mechanicalissueexists = mechanicalissueexists;
                    this.isdrivable = isdrivable;
            }

            getReqObject() {
              return this;
            }
    },
    ResModel:class {
        constructor(
            { 
                isinteriordamaged,
                warninglightsglowing,
                interiordamages,
                interiordamagecomment,
                isaccidental,
                damagebyfireflood,
                mechanicalissueexists,
                isdrivable,
                warninglights,
                mechanicalissues,
                conditionofcar
            }){

                
                this.warninglightsglowing = warninglightsglowing;
                this.isinteriordamaged = isinteriordamaged;
                this.interiordamages = interiordamages;
                this.interiordamagecomment = interiordamagecomment;
                this.isaccidental = isaccidental;
                this.damagebyfireflood = damagebyfireflood;
                this.mechanicalissueexists = mechanicalissueexists;
                this.isdrivable = isdrivable;
                this.warninglights = warninglights;
                this.mechanicalissues = mechanicalissues;
                this.conditionofcar = conditionofcar
        }

        getResObject() {
          return this;
        }
}
}

export default carConditionModel;