import {React,useEffect,useState,useRef,useCallback,useContext} from "react";
import './conditionandhistoryform.css';
import Select from "../custom-elements/select";
import {
  Checkbox,
  Button,
  Input, Form, Radio } from "antd";
import {useNavigate } from "react-router-dom";
import Topnavbar from "../topnavbarcomponent/topnavbar.component";
import { getData,updateData } from "../../commons/apiServices";
import { MechanicalIssueOptions,warningLightOptions,carConditionOptions } from "../../commons/selecteOptions"; 
import carConditionModel from '../../data-model/car-condition.model';
import { useDispatch, useSelector } from "react-redux";
import { FullScreenContext } from "../../providers/fullScreenContext";
import { ON_CHOICE_WARNING_LIGHTS,ON_CHOICE_MECHANICAL_ISSUES,ON_CHOICE_INTERIOR_DAMAGE } from "../../redux/slices/userChoiceSlice";

function Conditionandhistory() {

  //variables
  const formRef = useRef([]);
  const navigate = useNavigate();
  const { TextArea } = Input;

  //context 
  const fs = useContext(FullScreenContext);
  const userState = useSelector((state)=>state.userSession);
  const userChoice = useSelector(state=>state.userChoice);
  const dispatch = useDispatch();

  //handlers
  const onValidate = (value, name) => {
    setError(prev => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value }
    }));
  }

  const [error, setError] = useState({
    warningligts: {
      isReq: true,
      errorMsg: '',
      onValidateFunc: onValidate
    },
    mechanicalissues:{
      isReq: true,
      errorMsg: '',
      onValidateFunc: onValidate,
    },
    condition:{
      isReq: true,
      errorMsg: '',
      onValidateFunc: onValidate,
    }
  });

  const [form, setForm] = useState({
    warningligts: null,
    mechanicalissues:null,
    condition:null
  }); 
  
  const validateForm = () => {

    let isInvalid = false;

    //bruteforce technique for three scenarios
    if(!userChoice.userChoice.isIlluminatedWarning && !userChoice.userChoice.isMechanicalIssues){
      //when warning and mechanical issues is not selected
      error.warningligts.isReq = false
      form.warningligts = null;
      error.warningligts.errorMsg = '';
      error.mechanicalissues.isReq = false
      error.mechanicalissues.errorMsg = '';
      form.mechanicalissues = null;
      error.condition.isReq = true

    }else if(!userChoice.userChoice.isIlluminatedWarning && userChoice.userChoice.isMechanicalIssues){
       //when isIlluminatedWarning is not select but mechanical issues is selected
       error.warningligts.isReq = false
       form.warningligts = null;
       error.warningligts.errorMsg = '';
       error.mechanicalissues.isReq = true
       error.condition.isReq = true

    }else if (userChoice.userChoice.isIlluminatedWarning && !userChoice.userChoice.isMechanicalIssues){
      //when mechanical issues is not select but warning is selected
      error.warningligts.isReq = true
      error.mechanicalissues.isReq = false
      form.mechanicalissues = null;
      error.mechanicalissues.errorMsg = '';
      error.condition.isReq = true
    }  
    Object.keys(error).forEach((x) => {
      const errObj = error[x];
      if (errObj.errorMsg) {
        isInvalid = true;
      } else if (errObj.isReq && (!form[x] || form[x].length <= 0)) {
        isInvalid = true;
        onValidate(true, x);
      }
    });

    return !isInvalid;
  };


  const handleProvinceChange = useCallback((value, name) => {
    setForm(prev => ({
      ...prev,
      [name]: value
    }));
  }, []);
  
  const handleIlluminatedWarningChange = (e) => {
    dispatch(ON_CHOICE_WARNING_LIGHTS({isIlluminatedWarning:e.target.value}));
  };
  const handleMechanicalIssuesChange = (e) => {
    dispatch(ON_CHOICE_MECHANICAL_ISSUES({isMechanicalIssues:e.target.value}));
  };

  const handleInteriorDamageChange = (e) => {
    dispatch(ON_CHOICE_INTERIOR_DAMAGE({isInteriorDamage:e.target.value}))
  };

  //async api call
  const onFinish = async (values) => {
  
      const isValid = validateForm();

      if(!isValid){
        return
      }

        const requestObject = new carConditionModel.ReqModel(values);

        let warning = form.warningligts?.map(v=>v?.value);
        let mechanicalissue = form.mechanicalissues?.map(v=>v?.value);
        requestObject.warninglights = requestObject.warninglightsglowing ? warning : []
        requestObject.mechanicalissues = requestObject.mechanicalissueexists ? mechanicalissue :[]
        requestObject.conditionofcar = form.condition

        updateData('selfEvaluation/update/updateSelfEvaluationDetailsById',userState.userSession.uid,requestObject)
        .then((res) => {
          if(res.status){
            navigate('/dc');
          }
        });  
  };
  
//useEffects
  useEffect(()=>{
    
    // if (window.screen.orientation.lock) {
    //   window.screen.orientation.lock('portrait');
    // }
    // fs();

    document.documentElement.scrollTo(({
      top:0,
      left:0,
      behavior:"instant"
    }));

    //get Data from api for the form
    getData('selfEvaluation/read/getSelfEvaluationById',userState.userSession.uid)
    .then(res => {
       if(res.status) {
        if(res.response){

          let snapShots = res.response;

          dispatch(ON_CHOICE_WARNING_LIGHTS({isIlluminatedWarning:snapShots.warninglightsglowing}));
          dispatch(ON_CHOICE_MECHANICAL_ISSUES({isMechanicalIssues:snapShots.mechanicalissueexists}));
          dispatch(ON_CHOICE_INTERIOR_DAMAGE({isInteriorDamage:snapShots.isinteriordamaged}));
   
          (snapShots.conditionofcar || snapShots.warninglightsglowing || snapShots.mechanicalissueexists) && 
          setForm({
            condition:snapShots.conditionofcar,
            warningligts:warningLightOptions.filter(ops=>{
              if(snapShots.warninglights.indexOf(ops.value) !== -1){
                return ops
              }
            }),
            mechanicalissues:MechanicalIssueOptions.filter(ops=>{
              if(snapShots.mechanicalissues.indexOf(ops.value) !== -1){
                return ops
              }
            })
          })

          const responseObj = new carConditionModel.ResModel(snapShots);
          formRef.current.setFieldsValue(responseObj);
        }
      }
    })
  },[])

  return (
        <div className="conditionAndHistory_main">
            
            <Topnavbar title="Condition & History" prevpath="/cd"/>

            <Form
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                ref={formRef}
                onFinishFailed={()=>{return}}
              >
            <div className="formholder">
                <p className="question-head">
                Does your car have any illuminated warning lights?</p>
                  <Form.Item
                    name="warninglightsglowing"
                    rules={[
                      {
                        required: true,
                        message: "Please pick an item!",
                      },
                    ]}
                  >
                    <Radio.Group onChange={handleIlluminatedWarningChange} value={userChoice.userChoice.isIlluminatedWarning}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  
                  { 
                 userChoice.userChoice.isIlluminatedWarning ? 
                  <> 
                  <p className="question-head">Select all that apply: </p>
                   {/* check index.css for Style*/}
                   <Select
                    name="warningligts"
                    selectname="all that apply"
                    options={warningLightOptions}
                    isMulti
                    value={form.warningligts}
                    onChangeFunc={handleProvinceChange}
                    {...error.warningligts}
                    />
                
                    </>: <div></div>}
                    

                  <p className="question-head">
                    Is there any interior damage beyond normal wear?
                  </p>
                  <Form.Item
                    name="isinteriordamaged"
                    rules={[
                      {
                        required: true,
                        message: "Please check the any option",
                      },
                    ]}>
                    <Radio.Group onChange={handleInteriorDamageChange} value={userChoice.userChoice.isInteriorDamage}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  {userChoice.userChoice.isInteriorDamage && <div className="interiordamagermain">
                  <Form.Item name="interiordamages"
                  rules={[
                      {
                        required: true,
                        message: "Please check the any option",
                      },
                    ]}>
                    <Checkbox.Group style={{color: '#1890ff'}}
                    >
                      <Checkbox
                        style={{marginBottom: 10 }}
                        value="Rips or tears in seats"
                      >
                        Rips or tears in seats
                      </Checkbox>
                      <Checkbox
                        value="Damaged Entertainment Nav"
                        style={{
                          marginLeft: 0,
                          marginBottom: 10,
                        }}
                      >
                        Damaged Entertainment, Navigation or other systems
                      </Checkbox>
                      <Checkbox
                        value="Damaged Dashboard or Internal panel"
                        style={{
                          marginLeft: 0,
                          marginBottom: 10,
                        }}
                      >
                        Damaged dashboard or interior panels
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>

                  <div className="interiordamagecomments">
                  <Form.Item name="interiordamagecomment">
                    <TextArea
                      rows={3}
                      className="text-area"
                      placeholder="Comments..."
                    />
                  </Form.Item>
                  </div>
                  </div>}

                  <p className="question-head">
                Your car looks good, has it ever been in an Accident?</p>
                  <Form.Item
                    name="isaccidental"
                    rules={[
                      {
                        required: true,
                        message: "Please pick an item!",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  <p className="question-head">
                  Does your car have any damages caused by Fire/ Flood?</p>
                  <Form.Item
                    name="damagebyfireflood"
                    rules={[
                      {
                        required: true,
                        message: "Please pick an item!",
                      }
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  <p className="question-head">
                  Does your car have any mechanical issues?</p>
                  <Form.Item
                    name="mechanicalissueexists"
                    rules={[
                      {
                        required: true,
                        message: "Please pick an item!",
                      },
                    ]}
                  >
                    <Radio.Group onChange={handleMechanicalIssuesChange} value={userChoice.userChoice.isMechanicalIssues}>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                 
                   {userChoice.userChoice.isMechanicalIssues ? 
                   <>
                    <p className="question-head">Select all that apply:</p>
                      {/* check index.css for Style*/}
                         <Select
                          name="mechanicalissues"
                          selectname="all that apply"
                          options={MechanicalIssueOptions}
                          isMulti
                          value={form.mechanicalissues}
                          onChangeFunc={handleProvinceChange}
                           {...error.mechanicalissues}
                         />
                   </>
                   : <></>}


                  <p className="question-head">
                  Are there issues that would stop us from driving it?</p>
                  <Form.Item
                    name="isdrivable"
                    rules={[
                      {
                        required: true,
                        message: "Please pick an item!",
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value={true}>Yes</Radio.Button>
                      <Radio.Button value={false}>No</Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  <p className="question-head">What do you think is the condition of your Car?</p>
                   {/* check index.css for Style*/}
                  <Select
                    name="condition"
                    options={carConditionOptions}
                    value={form.condition}
                    selectname="Condition"
                    onChangeFunc={handleProvinceChange}
                    {...error.condition}
                  />

            </div>
            <div className="button-holder">
                  <Button
                            size="large"
                            htmlType="submit"
                            className="button-5"
                            // loading={loading}
                        >
                        Next
                    </Button>
                  </div>
            </Form>
      
        </div>
  );
}

export default Conditionandhistory;
