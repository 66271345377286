import firebase from '@firebase/app';
import '@firebase/storage';

const {firebaseConfig} = require('../config.json');

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export default storage ;
