import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import rootReducer from './rootReducer';
import { 
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const { reduxPersistConfig } = require('../config.json');

// //persist state even after page refresh
reduxPersistConfig.key = 'root';
reduxPersistConfig.storage = storage
reduxPersistConfig.whitelist = ['userSession','imageUpload','carDetails']

const persistedReducer = persistReducer(reduxPersistConfig, rootReducer);

export const store = configureStore({
    reducer:persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      },
    })
});

//persist state data in localstorage so that page refresh doesn't lose state.
export const persistor = persistStore(store);