import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userSession: {
    sheerAuthenticate: false,
    userNumber: "",
    leadid: "",
    urllead: false,
    store: "",
    execid:"",
    token: "",
    uid: "",
  },
};

const reducers = {
  ON_APP_INITIALIZE(state, action) {
    state.userSession = { ...state.userSession, ...action.payload };
  },
  ON_MOBILE_NUMBER_ACCEPTED(state, action) {
    state.userSession = { ...state.userSession, ...action.payload };
  },
  // ON_OTP_VALIDATE(state,action){
  //     state.userSession = {...state.userSession, ...action.payload};
  // },
  // AFTER_OTP_VALIDATE(state,action){
  //     state.userSession = {...state.userSession, ...action.payload};
  // },
  USER_SESSION_CLEANUP(state, action) {
    state.userSession = { ...action.payload };
  },
};

const sliceOption = {
  name: "userSession",
  initialState,
  reducers,
};

const userSessionSlice = createSlice(sliceOption);

//export all the actions from the slice
export const {
  ON_APP_INITIALIZE,
  ON_MOBILE_NUMBER_ACCEPTED,
  // ON_OTP_VALIDATE,
  // AFTER_OTP_VALIDATE,
  USER_SESSION_CLEANUP,
} = userSessionSlice.actions;

export default userSessionSlice.reducer;
