import React, { useEffect, useState, useContext } from "react";
import "./declaration.css";
import declarationPage from "./../../assets/declaration-view.png";
import { useNavigate } from "react-router-dom";
import Topnavbar from "../topnavbarcomponent/topnavbar.component";
import { checkCondition, getData, updateData } from "../../commons/apiServices";
import { useSelector } from "react-redux";
import { FullScreenContext } from "../../providers/fullScreenContext";

function Declarationpage() {
  //context
  const fs = useContext(FullScreenContext);

  const navigate = useNavigate();

  const userState = useSelector((state) => state.userSession);

  const [isChecked, setCheck] = useState(false);

  useEffect(() => {
    // if (window.screen.orientation.lock) {
    //   window.screen.orientation.lock("portrait");
    // }
    // fs();
  });

  const handleCheckedOne = (e) => {
    setCheck(e.target.checked);
  };

  // const handleCheckedTwo = (e) =>{
  //     checkCondition.checkedTwo = e.target.checked;
  // }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isChecked) {
      updateData(
        "selfEvaluation/update/updateSelfEvaluationDetailsById",
        userState.userSession.uid,
        {
          submittedon: Date.now(),
        }
      ).then((res) => {
        getData(
          `selfEvaluation/getVaahanAndUpdateSelfEvaluationByEvaluationId`,
          userState.userSession.uid,
          {}
        ).then((res) => {
          if (res.status) {
            checkCondition.checkedOne = false;
            checkCondition.checkedTwo = false;
          }
        });
        navigate("/ty");
        setCheck(false);
      });
    }
  };
  return (
    <div className="main">
      <Topnavbar title="Declaration" prevpath="/ch" />

      <div className="declaration-image">
        <img src={declarationPage} alt="declaration" />
      </div>

      <div className="content-holder">
        <div className="para-content">
          <p>
            I hereby certify that the information I provided is correct, best to
            my knowledge and authorize Group Landmark to use it.
          </p>
          <p>
            I understand that this information will be used to determine the
            best value of my car, in case of any mismatch in the information
            given by me, there will be negotiation on the resale value at the
            time of physical evaluation.
          </p>
        </div>
        <div className="ordered-list-wrapper">
          <ul>
            <li>My Mobile Number is a whatsapp able number</li>
            <li>
              I Understand, The Validity of Offer Price is for 7 Days from the
              date of submission
            </li>
            <li>I Consent to call, market for future communication</li>
          </ul>
        </div>
        <div className="checkboxvalidation">
          <input
            type="checkbox"
            onChange={(e) => {
              handleCheckedOne(e);
            }}
            id="myCheckbox1"
          />
          <label htmlFor="myCheckbox1">I Accept here</label>

          {/*<input type="checkbox" onChange={(e)=>{handleCheckedTwo(e)}} id="myCheckbox2"/>
                <label htmlFor="myCheckbox2">Receive Offer Price and further communication via <span className="WhatApp"><i className="fa-brands fa-whatsapp" style={{color: "#3dca16"}}></i>WhatsApp</span></label>*/}
          {/* <p className={!isChecked ? 'show' : 'hide'} style={{color:"red",textAlign:"center",top:"50%",position:"relative"}}>Please Accept All Terms & Conditions</p> */}
        </div>

        <div className="button-holder">
          <button
            disabled={!isChecked}
            className={!isChecked ? "button-5-disb" : "button-5"}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default Declarationpage;
