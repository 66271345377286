import React,{useEffect, useState,useContext} from "react";
import {MdScreenLockRotation } from "react-icons/md";
import { CameraContext } from "../../../providers/CameraContext";
import './leftviewcampage.css';
import carleftviewcam from "../../../assets/left-view.svg";
import { useNavigate } from "react-router-dom";
import Cameracomponent from "../../camera-component/camera-component";
import backEngine from '../../../assets/left-arrow.svg';
import { uid } from "uid";
import ErrorHandler from "../../errorhandler-component/errorhandler.component";
import uploadImage from "../../../commons/firebaseImageUpload";
import Loader from "../../loader-component/loader-component";
import { useDispatch, useSelector } from "react-redux";
import { ON_IMAGE_CAPTURED } from "../../../redux/slices/imageUploadSlice";
//always require below import statement
const {cameraFace} = require('../../../config.json');


function Leftviewcampage() {
  const navigate = useNavigate();
  const [isReady,setReady] = useState(false);
  const [image, setImage] = useState(null);
  const cameraMode = cameraFace;
  const userDevice = useContext(CameraContext);
  const [warnLandscape, setWarnLandscape] = useState(false);
  const [isLoading,setLoading] = useState(false);
  var temp = uid(16);
  const dispatch = useDispatch();
  const imagestates = useSelector(state=>state.imageUpload);
  const userState = useSelector(state=>state.userSession);
  useEffect(() => {

    console.log(imagestates);

    if (userDevice.size.x < userDevice.size.y) {
      setWarnLandscape(prevstate=> prevstate = true);
    } else {
      setWarnLandscape(prevstate=> prevstate = false);
    }
  }, [userDevice]);

  const sendPost = async () => {
    setLoading(true);
    uploadImage(image,temp,userState.userSession.uid).then(url=>{
      dispatch(ON_IMAGE_CAPTURED({leftview:url}))
      setLoading(false);
      navigate("/bvp");
    }).catch(error=>{
        console.log("firebase",error);
        <ErrorHandler/>          
      }); 
  }

  return (
    <>
    {  warnLandscape ? <div 
        style={{
        display:"flex",
        height:"100%",
        flexDirection: "column",
        justifyContent: "center"
        }}> 
          <div>
          <MdScreenLockRotation
                  size={200}
                  style={{
                    color: "black",
                    margin: "auto",
                    display: "block",
                    marginTop: "20px",
                  }}
                />
                <h1
                  style={{
                    color: "black",
                    textAlign: "center",
                    paddingTop: "20px",
                  }}
                >
                  Rotate your screen for better experience.
                </h1>
          </div>
          </div>
          : isLoading ? <Loader/> 
          : isReady ? <Cameracomponent 
                      sendPost={sendPost} 
                      nextpath="/bvp" 
                      setImage={setImage} 
                      cameraMode={cameraMode} 
                      /> 
          : <div className="car_leftview_main">
          <div className="back-button" onClick={()=>{navigate('/rvp')}}><img src={backEngine} alt="back"/></div>
            <div className="car_leftview_image">
                <img src={carleftviewcam} alt=""/>
            </div>
         
         <div className="car_leftview">
                <div className="car_leftview_text">
                <p>Next you will close the driver's door and move to the vehicle's left side and align yourself according</p> 
                <p>to this illustration. Please fit the vehicle within these lines.</p>
                </div>
         </div>

         <div className="button-holder">
            <button className="button-5" onClick={()=>{setReady(true)}}>Next</button>
            </div>

        </div>}
    </>
  );
  }


export default Leftviewcampage;
