import {React,memo} from "react";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carousel.css';
import noimagefound from '../../assets/no-image-found.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';


function CarouselComponent({carImages}) {

    //default Variable
    let keys = ["2ea62088","db76","11ed","afa1","0242ac120002","gh2123"];
    let altName = ['Front-View','Right-View','Left-View','Back-View','Odometer','interiorview'];
    
    return(
    <Carousel
        showArrows={true}
        showIndicators={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={false}
        interval={5000}
        transitionTime={500}
        >

        {
            carImages && carImages.map((img,index) => <div key={keys[index]}>
             <div style={{position:"absolute","bottom":"10px","left":"10px",color:"white"}}>
                <p>{altName[index]}</p>
            </div>
            <LazyLoadImage effect="blur" src={img ? img : noimagefound} alt={altName[index]} width="100%"/>
            </div>)
        }
        
        </Carousel>
)
}

export default memo(CarouselComponent);