import React from 'react';
import './topnavbar.css';
import leftArrowBlack from '../../assets/left-arrow-black.svg';
import { useNavigate } from 'react-router-dom';

function Topnavbar({title,prevpath}){
    const navigate = useNavigate();
    return (
        <div className="topnav-box">
            <div className='topnav-backbtn'><img alt={prevpath} src={leftArrowBlack}/></div>
            <p className='topnav-title' onClick={()=>{navigate(prevpath)}}>{title}</p>
        </div>
    )
}

export default Topnavbar;