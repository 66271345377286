import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userChoice:{
        isInterestedInNewCar:false,
        isIlluminatedWarning:false,
        isMechanicalIssues:false,
        isInteriorDamage:false
    }
}

const reducers = {
    ON_CHOICE_IS_INTERESTED_IN_NEWCAR(state,action){
        state.userChoice = {...state.userChoice, ...action.payload};
    },
    ON_CHOICE_WARNING_LIGHTS(state,action){
        state.userChoice = {...state.userChoice, ...action.payload};
    },
    ON_CHOICE_MECHANICAL_ISSUES(state,action){
        state.userChoice = {...state.userChoice, ...action.payload};
    },
    ON_CHOICE_INTERIOR_DAMAGE(state,action){
        state.userChoice = {...state.userChoice, ...action.payload};
    }
}

const sliceOption = {
    name:'userChoice',
    initialState,
    reducers
}

const userSessionSlice = createSlice(sliceOption);

//export all the actions from the slice
export const { 
    ON_CHOICE_IS_INTERESTED_IN_NEWCAR,
    ON_CHOICE_WARNING_LIGHTS,
    ON_CHOICE_MECHANICAL_ISSUES,
    ON_CHOICE_INTERIOR_DAMAGE
} = userSessionSlice.actions;

export default userSessionSlice.reducer;


